@import url("../styles//fonts.css");

.aboutUsContainer {
  display: flex;
  flex-direction: column;
  }
  
  .title {
    font-family: "Vinila Extended ExtraBold";
    /* color: rgb(231, 10, 10); */
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 3rem;
    margin-top: 50px;
      }
  
  .subtitle {
    font-size: 1.5rem;
    margin-bottom: 40px;
    color: #555;
  }
  
  .content {
    max-width: 800px;
    margin: 0 auto;
    text-align: left;
  }
  
  .section {
    margin-bottom: 40px;
  }
  
  .sectionTitle {
    font-family: "Azeret_Mono_Bold";
    color: rgb(231, 10, 10);
  
    display: flex;
    flex-direction: column;
    align-items: center;
    }
  
  .parrafos {
    font-family: "Azeret_Mono_Bold";
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  .botonatras {
    border: black solid 1px;
    border-radius: 5px;
    color: #da0707;
    font-size: 20px;
    margin-right: 5px;
    margin-top: 10px;
  }
  .containerImagen{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .imagen {
  width: 100px;
}
.mozolink {
  font-family: "Vinila Extended ExtraBold";
  color: rgb(231, 10, 10);
}
