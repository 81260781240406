@import url("../styles//fonts.css");
body {
  background-color: whitesmoke;
}


.container1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
.titulo {
  font-family: "Vinila Extended ExtraBold";
  color: rgb(229, 43, 43);
  font-size: 2rem;
}

.subtitulo {
  font-family: "Gotham Medium";
  margin-bottom: 3rem;
}

.label {
  font-family: "Vinila Extended ExtraBold";
  margin: auto;
  margin-bottom: 6px;
  margin-right: 2px;
}
.input {
  border: 1px solid black;
  border-radius: 5px;
  display: flex;
}
.contlabel1 {
  display: flex;
  flex-direction: column;
}
.contlabel2 {
  display: flex;
  flex-direction: column;
  margin-left: 5px;
}

.containerlabel {
  display: flex;
  justify-content: center;
  text-align: center;
}
.containerboton {
  display: flex;
  justify-content: center;
}
.boton {
  font-family: "Azeret_Mono_regular";
  background-color: rgb(221, 25, 25);
  border-color: #dc3545;
  color: whitesmoke;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  font-size: 14px;
  margin-top: 20px;
}
.boton:hover{
  background-color: rgb(243, 3, 3);
  border-color: rgb(243, 3, 3);
}
.containerCard{
  background-color: whitesmoke;
  font-family: "Gotham Medium";
}
.imagen{
  width: 65px;
  margin-top: 10px;
}

@media (max-width: 767px) {
  .label {
    font-family: "Vinila Extended ExtraBold";
    margin: auto;
    margin-bottom: 6px;
  }
  .containerlabel {
    display: flex;
    justify-content: center;
    text-align: center;
    flex-direction: column;
  }
  
  
}
