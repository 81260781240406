@import url("../styles//fonts.css");
.titulo {
  font-family: "Vinila Extended ExtraBold";
}
.containerlinks {
  justify-content: space-evenly;
}
.botonAtras {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.boton {
  font-family: "Azeret_Mono_regular";
  background-color: rgb(221, 25, 25);
  border-color: #da0707;
  color: whitesmoke;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  margin-top: 2rem;
}
.boton:hover {
  background-color: rgb(243, 3, 3);
}

.iconos {
  border: #da0707 solid 1px;
  width: 15rem;
}
.subtitulo {
  font-family: "Vinila Extended ExtraBold";
  color: rgb(231, 10, 10);
  margin-bottom: 3rem;
}
.footer {
  text-align: center;
  margin-top: 20px;
}

.copyright {
  font-size: 14px;
  color: gray;
}
.botoninfo {
  border: black solid 1px;
  border-radius: 8px;
  /* border: none; */
  font-family: "Azeret_Mono_Bold";
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  font-size: 13px;
}
.icono {
  font-size: 16px;
  color: rgb(221, 25, 25);
}

@media (max-width: 767px) {
  .container{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .containerlinks {
 display: flex;
 flex-direction: column;
}

}