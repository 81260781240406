@import url("../styles//fonts.css");
/* body {
  background-color:rgb(221, 25, 25);
} */

.container {
  /* background-image: url("../imagenes/mozoanimado.jpg"); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.formContainer {
  margin-top: 2rem;
}
.titulo {
  font-family: "Vinila Extended ExtraBold";
}
.mozolink {
  font-family: "Vinila Extended ExtraBold";
  color: rgb(231, 10, 10);
}
.mensajeAdicional {
  font-family: "Vinila Extended ExtraBold";
}
.containerboton {
  display: flex;
  justify-content: center;
}
.checkbox {
  border: 1px solid red;
}

.checkbox:checked {
  background-color: #f00404;
  border-color: #da0707;
}
.botonenviar {
  font-family: "Azeret_Mono_regular";
  background-color: rgb(221, 25, 25);
  border-color: #da0707;
  color: whitesmoke;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.botonenviar:hover {
  background-color: rgb(243, 3, 3);
}
.labels {
  /* font-family: Vinila Variable Test Regular; */
  font-family: "Azeret_Mono_regular";
  border: 1px solid black;
  border-radius: 5px;
  text-align: center;
  width: 14rem;
  margin-bottom: 5px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
/* .botonmenu{
  font-family:  "Azeret_Mono_regular";

  margin-top: -20px;
  border: 1px solid black;
  border-radius: 5px;
  text-align: center;
  width: 14rem;
  margin-bottom: 5px;

} */
.inputmensaje {
  border: 2px solid red;
  border-radius: 10px;
  text-align: center;
  width: 17rem;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.tituloadicional {
  font-family: "Azeret_Mono_regular";
  text-align: center;
}
.adicional {
  display: flex;
  flex-direction: column;
  text-align: center;
}
.nav {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-bottom: 2rem;
  margin-top: 2px;
}
.containermenu {
  display: flex;
  justify-content: center;
  align-items: center;
}
.botonmenu {
  color: whitesmoke;
  background-color: rgb(221, 25, 25);
  font-family: "Azeret_Mono_regular";
  border: 1px solid black;
  border-radius: 5px;
  text-align: center;
  width: 30%;
  border: none;
  margin-bottom: 5px;
  /* margin-right: 22px; */
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  height: 25px;
  /* margin-top: 12px; */
  margin-bottom: 1rem;
}
.botonmenu:hover {
  background-color: #da0707;
  color: white;
}
.imagen {
  width: 80px;
}
.footer {
  text-align: center;
  margin-top: 20px;
}

.copyright {
  font-size: 14px;
  color: gray;
  margin-bottom: 0px;
  margin-top: 73px;
}
.ingresepin {
  border: black solid 1px;
  border-radius: 8px;
  text-align: center;
}
.iniciarsesion {
  font-family: "Vinila Extended ExtraBold";
}
.botoninfo {
  border: black solid 1px;
  border-radius: 8px;
  /* border: none; */
  font-family: "Azeret_Mono_Bold";
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  font-size: 13px;
}
.icono {
  font-size: 16px;
  color: rgb(221, 25, 25);
}

@media (max-width: 767px) {
  .botonmenu:hover {
    background-color: #da0707;
    color: white;
  }
  .botonenviar:hover {
    background-color: rgb(243, 3, 3);
  }
}
