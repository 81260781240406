@font-face {
  font-family: "Gotham Bold";
  src: url("../fonts/Gotham Bold.otf") format("truetype");
}

/* Gotham\ Bold.otf */
@font-face {
  font-family: "Azeret_Mono_Bold";
  src: url("../fonts/AzeretMono-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Azeret_Mono_regular";
  src: url("../fonts/AzeretMono-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Gotham Book";
  src: url("../fonts/Gotham Book.otf") format("truetype");
}
@font-face {
  font-family: "Gotham Medium";
  src: url("../fonts/Gotham Medium.otf") format("truetype");
}
@font-face {
  font-family: "Vinila Extended ExtraBold";
  src: url("../fonts/Vinila Extended ExtraBold.otf") format("truetype");
}
@font-face {
  font-family: "Vinila Variable Test Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Vinila Variable Test Regular"),
    url("../fonts//VinilaTest-Regular.woff") format("woff");
}
@font-face {
  font-family: "Vinila Variable";
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/Vinila\ Variable.ttf") format("truetype");
}
@font-face {
  font-family: "Café Françoise";
  src: url("../fonts/Café Françoise.otf") format("truetype");
}
