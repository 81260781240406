@import url("../styles//fonts.css");

body {
  background-color: whitesmoke;
}
.containerPrincipal {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-bottom: 100px;
}
.containerPrincipal2 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.titulo {
  display: flex;
  font-family: "Café Françoise", sans-serif;

  /* font-family: "Vinila Extended ExtraBold"; */
  margin-bottom: 2rem;
  color: #2a51ac;
  justify-content: center;
  font-size: 53px;
}
.subtitulo {
  display: flex;
  flex-direction: column;
  text-align: center;
  font-family: "Azeret_Mono_regular";
  margin-bottom: 2rem;
  margin-top: 20px;
}

.elpedido {
  font-family: "Azeret_Mono_regular";

  color: rgb(11, 122, 219);
}
.eliminar {
  font-family: Gotham Medium;
}
.mesasDisponibles {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-bottom: 3rem;
  font-size: 24px;
}

.mesasDisponiblesCount {
  font-family: Gotham Medium;
}
.estadoMesa {
  display: flex;
  flex-direction: column;
  margin-bottom: 3rem;
  border: #121212 solid 1px;
  border-radius: 10px;
  width: 18rem;
  height: auto;
  text-align: center;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.botonLimpiar {
  width: 154px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.containerBoton {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}
.containerEstado {
  display: flex;
  flex-direction: column;
  font-size: 17px;
}
.mesaDisponible{
  color: green;

}
.estadoDisponible {
  color: green;
  font-family: Gotham Medium;
}

.estadoNoDisponible {
  color: inherit;
  font-family: Gotham Medium;
}
.solicitado {
  color: rgb(11, 122, 219);
  font-size: 20px;
}
.mensajeRecibido {
  color: rgb(11, 122, 219);
}
.icono {
  font-size: 28px;
  color: black;
}
.icono:hover {
  color: #2a51ac;
}
.titulomodal {
  text-align: center;
}
.botonmodal {
  color: antiquewhite;
  background-color: rgb(206, 40, 40);
  border: none;
}
.botonmodal:hover {
  background-color: rgb(223, 13, 13);
}
.numeroMensajes {
  font-weight: bold; /* Hacer el número de mensajes en negrita */
  width: 25px; /* Ancho del contenedor */
  height: 25px; /* Altura del contenedor */
  border-radius: 50%;
}
.mensajeNuevo {
  animation: bounce 0.5s infinite alternate; /* Nombre de la animación, duración, iteraciones e efecto */
}
.mensajeModal {
  border: #2a51ac solid 1px;
  border-radius: 8px;
  margin-bottom: 5px;
  display: flex;
  justify-content: center;
}
.containercerrar {
  display: flex;
  /* justify-content: end; */
  justify-content: end;
}
.cerrarSesion {
  font-family: "Azeret_Mono_regular";
  background-color: rgb(221, 25, 25);
  border-color: #dc3545;
  color: whitesmoke;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  font-size: 13px;
  margin-top: 20px;
  height: 25px;
  margin-left: 10px;
}
.cerrarSesion:hover {
  background-color: rgb(243, 3, 3);
}
.containerApagar {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.containerpin {
  font-family: "Azeret_Mono_regular";
  margin-top: 5px;
}
.pin {
  color: #da0707;
}
@keyframes bounce {
  from {
    transform: translateY(0); /* Desde la posición inicial */
  }
  to {
    transform: translateY(-10px); /* Hacia arriba */
  }
}
.containerimagen {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 5px;
  margin-top: 15px;
}
.imagen {
  display: flex;
  flex-direction: column;
  width: 65px;
}
.mozolink {
  font-family: "Azeret_Mono_Bold";
  color: rgb(231, 10, 10);
  font-size: 20px;
}
.tituloloader {
  font-family: "Vinila Extended ExtraBold";
}
.seccion {
  display: flex;
  flex-direction: column;
}
.botonok {
  color: rgb(11, 122, 219);
  /* border: 1px solid black; */
  border: none;
  width: 27px;
  text-align: center;
  font-size: 16px;
  margin-left: 5px;
  padding: inherit;
  border-radius: 8px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.botonok:hover {
  color: white;
  background-color: rgb(11, 122, 219);
}
.loader {
  width: 120px;
  height: 20px;
  -webkit-mask: linear-gradient(90deg, #000 70%, #0000 0) left/20% 100%;
  background: linear-gradient(#000 0 0) left -25% top 0 /20% 100% no-repeat #ddd;
  animation: l7 1s infinite steps(6);
}
@keyframes l7 {
  100% {
    background-position: right -25% top 0;
  }
}

.campanita {
  color: rgb(231, 10, 10);
  font-size: 1.5rem;
  transition: transform 0.3s ease; /* Transición de animación */
}
@keyframes swingBell {
  0% {
    transform: rotate(0);
  }
  25% {
    transform: rotate(-10deg);
  }
  50% {
    transform: rotate(10deg);
  }
  75% {
    transform: rotate(-10deg);
  }
  100% {
    transform: rotate(0);
  }
}

/* Aplicar la animación de oscilación a la campanita cuando está activa */
.campanita.active {
  animation: swingBell 1s infinite; /* Aplicar la animación de manera continua */
  transform-origin: top center; /* Establecer el punto de origen de la rotación */
}

.fadeOut {
  animation: fadeOut 1s forwards;
  pointer-events: none;
  opacity: 0.5;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.5;
  }
}

.disableButtons {
  pointer-events: none;
  opacity: 0.5;
}
@media (max-width: 767px) {
  .containercerrar {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .cerrarSesion {
    margin-left: 0px;
    width: auto;
  }
  
}
