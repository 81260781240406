@import url("../styles//fonts.css");

.titulo {
  font-family: "Vinila Variable";
  color: #da0707;
}
.subtitulo {
  font-family: "Azeret_Mono_Bold";
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 5rem;
}
.botonatras {
  font-family: "Azeret_Mono_regular";
  background-color: rgb(221, 25, 25);
  border-color: #da0707;
  color: whitesmoke;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  margin-top: 2rem;
}
.botonatras:hover {
  background-color: rgb(243, 3, 3);
}
.cardd {
  background-color: whitesmoke;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.imagen {
  display: flex;
  flex-direction: column;
  width: 35px;
  margin-bottom: 10px;
}

@media (max-width: 767px) {
  .botonatras {
    font-size: 10px;
  }
}