@import url("../styles//fonts.css");
ul {
  list-style-type: none;
}

.titulo {
  font-family: "Vinila Extended ExtraBold";
  /* color: #2a51ac; */
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 3rem;
  margin-top: 5px;
}
.titulo2 {
  font-family: "Vinila Extended ExtraBold";
  /* color: #2a51ac; */
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 3rem;
  margin-top: 65px;
}
.categoria {
  font-family: "Azeret_Mono_Bold";
  color: rgb(231, 10, 10);

  display: flex;
  flex-direction: column;
  align-items: center;
}
.categoria2 {
  font-family: "Café Françoise", sans-serif;
  color: rgb(231, 10, 10);
  font-size: 20px;
  display: flex;
  flex-direction: column;
}
.categoria3 {
  font-family: "Azeret_Mono_Bold";
  color: rgb(231, 10, 10);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;
}

.subtitulo {
  font-family: "Azeret_Mono_Bold";
  color: rgb(231, 10, 10);
  display: flex;
  flex-direction: column;
  align-items: center;
}
/* .containerPrincipal{
  display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
} */
.containerPrincipal2 {
  /* display: flex; */
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin-bottom: 5rem;
}
.containerPrincipal3 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin-bottom: 5rem;
}

.containerform {
  display: flex;
  flex-direction: column;
  text-align: end;
  /* align-items: center; */
}
.container1 {
  display: flex;
  /* justify-content: space-around; */
  align-items: center;
  flex-direction: column;
}
.container2 {
  display: flex;
  flex-direction: column;
}
.connombre {
  display: flex;
  flex-direction: column;
  align-items: center;
}
/* .container3{
  display: flex;
  flex-direction: column;

} */
.container4 {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5rem;
}
.container5 {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
}
.container6 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.container7 {
  display: flex;
  flex-direction: column;
}

.nombreCategoria {
  font-family: "Café Françoise", sans-serif;

  display: flex;
  align-items: center;
  flex-direction: column;
}
.categoriaConteiner {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  justify-content: center;
  flex-direction: column;
}
.label {
  font-family: "Azeret_Mono_Bold";
}
.label2 {
  font-family: "Azeret_Mono_Bold";
  margin-bottom: 10px;
}
.label3 {
  font-family: "Azeret_Mono_Bold";
  font-size: 22px;
}

.input {
  border: #1657ef solid 1px;
  border-radius: 8px;
  margin-bottom: 5px;
  font-family: "Azeret_Mono_Bold";
}
.inputprecio {
  border: #1657ef solid 1px;
  border-radius: 8px;
  margin-bottom: 5px;
  font-family: "Azeret_Mono_Bold";
  margin-bottom: 2rem;
}

.input2 {
  border: #1657ef solid 1px;
  border-radius: 8px;
  margin-bottom: 5px;
  margin-right: 5px;
  font-family: "Azeret_Mono_Bold";
}
.preciocont {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.input3 {
  display: flex;
}
.containerBotones {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 4rem;
}
.containerdescirpcion {
  display: flex;
  margin-top: 1rem;
  flex-direction: column;
  align-items: center;
}
.containerProductos {
  border: #da0707 solid 1px;
  border-radius: 8px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  padding: 10px;
}
.containeragregar {
  display: flex;
  flex-direction: column;
  text-align: center;
}
.eliminarproducto {
  border: black solid 1px;
  background-color: #da0707;
  border-radius: 5px;
  color: whitesmoke;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.eliminarcategoria {
  font-family: "Vinila Extended ExtraBold";

  border: black solid 1px;
  background-color: whitesmoke;
  border-radius: 5px;
  color: #da0707;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  height: 35px;
}

.eliminarcategoria:hover {
  color: whitesmoke;
  background-color: #da0707;
}
.eliminarModal {
  font-family: "Vinila Extended ExtraBold";

  border: black solid 1px;
  background-color: whitesmoke;
  border-radius: 5px;
  color: #da0707;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  height: 35px;
}
.eliminarModal:hover {
  color: whitesmoke;
  background-color: #da0707;
}

.botonAgrcategoria {
  font-family: "Vinila Extended ExtraBold";

  border: black solid 1px;
  border-radius: 5px;
  background-color: whitesmoke;
  margin-right: 4px;
  color: #1657ef;
  /* width: 180px; */
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  height: 35px;
}
.botonAgrcategoria:hover {
  color: whitesmoke;
  background-color: #1657ef;
}
.guardarModal {
  font-family: "Vinila Extended ExtraBold";
  border: black solid 1px;
  border-radius: 5px;
  background-color: whitesmoke;
  margin-right: 4px;
  color: #1657ef;
  /* width: 180px; */
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  height: 35px;
}
.guardarModal:hover {
  color: whitesmoke;
  background-color: #1657ef;
}

.botonAgrmenu {
  border: black solid 1px;
  border-radius: 5px;
  color: #da0707;
  width: 180px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.containerinputs {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
}
.containerBotones2 {
  display: flex;
  margin-top: 2rem;
}
.agregarprod {
  font-family: "Gotham Medium";

  border: black solid 1px;
  background-color: #1657ef;
  border-radius: 5px;
  color: whitesmoke;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  width: max-content;
  margin-right: 10px;
  width: 180px;
  width: 188px;
  font-size: 14px;
  height: 42px;
}
.agregarprod2 {
  font-family: "Azeret_Mono_regular";

  border: black solid 1px;
  background-color: #1657ef;
  border-radius: 5px;
  color: whitesmoke;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  width: max-content;
  margin-right: 10px;
  height: 35px;
}

.agregarprod:hover {
  background-color: #0948db;
}
.agregarprod2:hover {
  background-color: #0948db;
}

.eliminarprd {
  font-family: "Azeret_Mono_regular";

  border: black solid 1px;
  background-color: #d81c1c;
  border-radius: 5px;
  color: whitesmoke;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  width: max-content;
}
.eliminarprd:hover {
  background-color: #ea0d0d;
}

.botonAtras {
  font-family: "Azeret_Mono_regular";
  background-color: rgb(221, 25, 25);
  border-color: #da0707;
  color: whitesmoke;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  margin-top: 2rem;
}
.botonAtras:hover {
  background-color: rgb(243, 3, 3);
}
.contbotonmenu {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.btnvermenu {
  font-family: "Gotham Medium";
  border: black solid 1px;
  background-color: whitesmoke;
  border-radius: 5px;
  color: #da0707;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  width: 180px;
  height: 42px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btnvermenu:hover {
  color: whitesmoke;
  background-color: #da0707;
}
/* estilos modal */

.modalHeader {
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 0%;
}
.cerrarMOdal {
  font-family: "Gotham Medium";
  border: none;
  background-color: white;
  border-radius: 8px;
  margin-top: 5px;
  margin-right: 5px;
}
.containerCerrar {
  display: flex;
  justify-content: end;
}

@media (max-width: 767px) {
  .containerPrincipal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .categoriaConteiner {
    flex-direction: column;
  }
  .container1 {
    flex-direction: column;
  }
  .connombre {
    display: flex;
    flex-direction: column;
  }

  .containerform {
    display: flex;
    flex-direction: column;
    /* text-align: end; */
    text-align: center;
    align-items: center;
  }

  .containerdescirpcion {
    display: flex;
    margin-top: 1rem;
    flex-direction: column;
  }
  .preciocont {
    display: flex;
    flex-direction: column;
  }
  .containerBotones {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
  }
  .agregarprod {
    margin-bottom: 10px;
  }
  .agregarprod2 {
    font-size: 13px;
    height: 30px;
  }
  .eliminarprd {
    font-size: 13px;
    height: 30px;
  }
  .container7 {
    display: flex;
    flex-direction: column;
  }
  .container8 {
    display: flex;
    flex-direction: column;
    padding-left: 0rem;
  }
  .containerBotones2 {
    justify-content: center;
  }

  .botonAtras {
    margin-top: 1rem;
    margin-bottom: 2rem;
    font-size: 11px;
  }
  .titulo {
    font-size: 27px;
    text-align: center;
  }
  .eliminarcategoria {
    height: 51px;
    font-size: 13px;
  }
  .botonAgrcategoria {
    height: 51px;
    font-size: 13px;
  }
  .label3 {
    font-size: 17px;
  }
}
