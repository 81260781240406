@import url("../styles//fonts.css");

.bodyContainer {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-top: 10rem;
}
.titulo {
    display: flex;
    flex-direction: column;
    font-family: "Vinila Extended ExtraBold";
    margin-bottom: 2rem;
    /* color: #2a51ac; */
    justify-content: center;
  }
  
.card {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.form {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
}
.input {
  border-radius: 8px;
  margin-bottom: 5px;
  width: 15rem;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border: 1px solid black;
  text-align: center;
}
.boton {
  color: antiquewhite;
  background-color: rgb(206, 40, 40);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  margin-top: 1rem;
  border: 1px solid black;

}
.boton:hover {
  background-color: rgb(223, 13, 13);
}
.error {
  color: red;
}
.containerimagen{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 5px;
  margin-top: 15px;

}
.imagen{
  display: flex;
  flex-direction: column;
  width: 65px;
}
.mozolink{
  font-family:  "Azeret_Mono_Bold";
  color: rgb(231, 10, 10);
  font-size: 20px;
}
