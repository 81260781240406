@import url("../styles//fonts.css");
@import url("https://fonts.cdnfonts.com/css/nunito-2");

.title {
  font-family: "Café Françoise", sans-serif;
  font-size: 50px;
  color: #da0707;
  margin-bottom: 2rem;
}
.subcategorie {
  font-family: "Azeret_Mono_Bold";
  display: flex;
  justify-content: center;
}

.menuContain {
  font-family: "Azeret_Mono_regular";
  display: flex;
  justify-content: space-between;
  border: #da0707 solid 1px;
  border-radius: 5px;
  padding: 10px;
}
.nombre {
  color: #da0707;
  font-family: "Azeret_Mono_Bold";
}
.categoria {
  font-family: "Café Françoise", sans-serif;
  font-size: 25px;
}

.navbar {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 16px 16px 16px 8px;
  background-color: whitesmoke;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.navbar-collapse {
  z-index: 1500;
}
.containerPrincipal {
  display: flex;
  justify-content: center;
}
.container {
  margin-top: 90px;
  position: relative;
  z-index: 0;
  padding-top: 60px;
}
.precio {
  display: flex;
  justify-content: end;
}
.botonatras {
  border: black solid 1px;
  border-radius: 5px;
  color: #da0707;
  font-size: 20px;
  margin-right: 5px;
}
.containerProducto {
  display: flex;
}
.nombreBar {
  display: flex;
  text-align: center;
  margin: auto;
  font-family: "Azeret_Mono_Bold";
  font-size: 20px;
}
.tituloloader {
  font-family: "Vinila Extended ExtraBold";
}
.loader {
  width: 120px;
  height: 20px;
  -webkit-mask: linear-gradient(90deg, #000 70%, #0000 0) left/20% 100%;
  background: linear-gradient(#000 0 0) left -25% top 0 /20% 100% no-repeat #ddd;
  animation: l7 1s infinite steps(6);
}
.searchInput {
  border: black solid 1px;
  margin-left: 5px;

  display: flex;
  text-align: center;
}
.searchButton {
  border: black solid 1px;
  border-radius: 5px;
  color: #da0707;
  font-size: 20px;
}
@keyframes l7 {
  100% {
    background-position: right -25% top 0;
  }
}

@media (max-width: 767px) {
  .categoria {
    text-align: center;
  }

}
