@import url("../styles//fonts.css");
.container{
    display: flex;
    flex-direction: column;
    max-width: 100%;
    background-color: darkgray;
    margin-top: auto;
    height: 60px;
    justify-content: center;
}
.configuraciones{
    font-family: "Vinila Extended ExtraBold";
}
.icono{
    font-size: 20px;
    margin-right: 5px;
}
.containerIcono{
    display: flex;
    align-items: center;
    justify-content: center;
}